import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Navbar: React.FC = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const location = useLocation();

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const getLogoColor = () => {
        if (location.pathname === '/questions' || location.pathname === '/how-to-play') {
            return '#7B4528';
        } else {
            return '#FFFFF0';
        }
    };

    return (
        <>
            <div className="bg-transparent w-full flex justify-end p-3 absolute z-50">
                <button onClick={toggleSidebar}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth={3} stroke={getLogoColor()} className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>
                </button>
            </div>


            <div
                className={`fixed inset-0 bg-black bg-opacity-50 z-50 ${
                isSidebarOpen ? 'visible' : 'hidden'
                } transition-opacity`}
                onClick={toggleSidebar}
            ></div>


            <div
                className={`fixed inset-y-0 right-0 w-[200px] bg-[#FFFFF0] z-50 transform ${
                isSidebarOpen ? 'translate-x-0' : 'translate-x-[150%]'
                } transition-transform ease-in-out`}
            >
                <button
                    className="absolute top-4 right-[200px] w-10"
                    onClick={toggleSidebar}
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFFFF0" className="w-6 h-6">
                        <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                    </svg>
                </button>
                <ul className="p-3 space-y-4 mt-7 text-[#901B2B] text-[18px]">
                    <li>
                        <Link to="/"
                        className="pl-2" 
                        onClick={toggleSidebar}>
                        Homepage
                        </Link>
                        <div className='h-[1px] w-[170px] bg-[#D9D9D9]'></div>
                    </li>
                    <li>
                        <Link
                        to="/how-to-play"
                        className="pl-2"
                        onClick={toggleSidebar}
                        >
                        How To Play
                        </Link>
                        <div className='h-[1px] w-[170px] bg-[#D9D9D9]'></div>
                    </li>
                    <li>
                        <Link
                        to="/products"
                        className="pl-2"
                        onClick={toggleSidebar}
                        >
                        Products
                        </Link>
                        <div className='h-[1px] w-[170px] bg-[#D9D9D9]'></div>
                    </li>
                    <li>
                        <Link
                        to="/contact"
                        className="pl-2"
                        onClick={toggleSidebar}
                        >
                        Contact
                        </Link>
                        <div className='h-[1px] w-[170px] bg-[#D9D9D9]'></div>
                    </li>
                </ul>
                <img src="/images/A-Amusphere.png" alt="logo" className='w-16 h-16 absolute right-0 bottom-0'></img>
            </div>
        </>
    );
};

export default Navbar;
