import React from 'react';

const OrganizationPage: React.FC = () => {
  return (
    <div className="flex flex-col flex-grow items-center justify-center">
      <h2>Organization</h2>
      <p>Welcome to the Organization Page!</p>
    </div>
  );
};

export default OrganizationPage;
