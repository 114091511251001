import React from 'react';
import { Link } from 'react-router-dom';

const ContactPage: React.FC = () => {
  return (
    <div className="w-full h-full flex flex-col items-center justify-start bg-[#901B2B]">
        <div className='mt-[60px] w-[300px] h-[250px] flex items-center justify-between flex-col md:gap-10'>
            <h2 className='text-[40px] lg:text-[48px] text-[#FFFFF0]'>CONTACT</h2>
            <div className='h-auto w-full flex gap-3 md:gap-4 lg:gap-5 justify-evenly items-center flex-col'>
                <Link to='https://instagram.com'>
                    <button className='w-[280px] md:w-[350px] lg:w-[420px] h-[40px] md:h-[50px] lg:h-[60px] rounded-full flex items-center justify-between' style={{ backgroundColor: 'rgba(255, 255, 240, 0.6)' }}>
                        <div className='ml-3 flex justify-center items-center'>
                            <img src='/images/ContactPage/instagram.png' alt='instagram' className='w-[32px] md:w-[40px] lg:w-[48px] h-full object-cover rounded-full' />
                        </div>
                        <div className='w-full'>
                            <p className='text-[18px] md:text-[24px] lg:text-[30px] text-[#901B2B]'>INSTAGRAM</p>
                        </div>
                    </button>
                </Link>
                <Link to='https://whatsapp.com'>
                    <button className='w-[280px] md:w-[350px] lg:w-[420px] h-[40px] md:h-[50px] lg:h-[60px] rounded-full flex items-center justify-between' style={{ backgroundColor: 'rgba(255, 255, 240, 0.6)' }}>
                        <div className='ml-3 flex justify-center items-center'>
                            <img src='/images/ContactPage/whatsapp.png' alt='whatsapp' className='w-[32px] md:w-[40px] lg:w-[48px] h-full object-cover rounded-full' />
                        </div>
                        <div className='w-full'>
                            <p className='text-[18px] md:text-[24px] lg:text-[30px] text-[#901B2B]'>WHATSAPP</p>
                        </div>
                    </button>
                </Link>
                <Link to='https://gmail.com'>
                    <button className='w-[280px] md:w-[350px] lg:w-[420px] h-[40px] md:h-[50px] lg:h-[60px] rounded-full flex items-center justify-between' style={{ backgroundColor: 'rgba(255, 255, 240, 0.6)' }}>
                        <div className='ml-3 flex justify-center items-center'>
                            <img src='/images/ContactPage/gmail.png' alt='email' className='w-[32px] md:w-[40px] lg:w-[48px] h-full object-cover rounded-full' />
                        </div>
                        <div className='w-full'>
                            <p className='text-[18px] md:text-[24px] lg:text-[30px] text-[#901B2B]'>EMAIL</p>
                        </div>
                    </button>
                </Link>
            </div>
        </div>
    </div>
  );
};

export default ContactPage;
