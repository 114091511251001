import React from 'react';
import { Link } from 'react-router-dom';

const HowToPlayPage: React.FC = () => {
  return (
    <div 
    className="w-full h-full flex flex-col items-center justify-start gap-5 text-center py-[1 0vh]"
    style={{
      backgroundImage: `url(${process.env.PUBLIC_URL}/images/QuestionPage/bg.png)`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}
    > 
      <div className='mt-[90px] w-[200px] lg:w-[500px] h-[60px] lg:h-[80px] bg-[#FFFFF0] rounded-full flex justify-center items-center'>
        <h2 className='text-[#901B2B] text-[26px] lg:text-[48px] font-bold'>HOW TO PLAY</h2>
      </div>
      <Link to="/product" className="text-[#10181f] z-40 mt-[20px] lg:mt-[50px]">
        <div className='w-[260px] lg:w-[360px] h-[160px] lg:h-[240px] bg-white rounded-[26px] text-center flex flex-col justify-between overflow-hidden text-[18px] lg:text-[36px]'>
          <img src='/images/LandingPage/product.png' alt='logo' className='w-full h-[125px] lg:h-[180px]' />       
          <p className='w-full h-full text-[#901B2B] pt-1'>ROYAL INTRIGUE</p>
        </div>
      </Link>
    </div>
  );
};

export default HowToPlayPage;
