import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import OrganizationPage from './pages/OrganizationPage';
import HowToPlayPage from './pages/HowToPlayPage';
import Navbar from './components/Navbar';
import QuestionPage from './pages/QuestionPage';
import Form from './pages/Form';
import ContactPage from './pages/ContactPage';

function App() {
  return (
    <Router>
      <div className="h-screen w-full flex flex-col font-bebas">
        <Navbar />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/organization" element={<OrganizationPage />} />
          <Route path="/how-to-play" element={<HowToPlayPage />} />
          <Route path="/questions" element={<QuestionPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/form" element={<Form />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
