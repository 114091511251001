import React, { useState } from 'react';

interface Question {
  tujuan: string;
  pertanyaan: string;
  penilaian: string;
  penilai: string;
}

const QuestionPage: React.FC = () => {
  // Tracking finished atau belum
  const [end, setEnd] = useState(false);

  // Tracking jumlah pertanyaan
  const [count, setCount] = useState<number>(1);

  // Pertanyaan awal
  const startingIndex = Math.floor(Math.random() * questions.length);
  const afterFirstQuestionQuestions = [...questions];
  afterFirstQuestionQuestions.splice(startingIndex, 1);

  const [currentQuestion, setCurrentQuestion] = useState<Question>(questions[startingIndex]);
  const [remainingQuestions, setRemainingQuestions] = useState<Question[]>(afterFirstQuestionQuestions);

  // Mengambil pertanyaan random
  const getRandomQuestion = () => {
    if (remainingQuestions.length === 0 && count !== 3) {
      return finished;
    }
    if (count === 3) {
      setCount(0);
      return specialQuestion;
    } else {
      const randomIndex = Math.floor(Math.random() * (remainingQuestions.length + 1));
      if (randomIndex === remainingQuestions.length) {
        setCount(count + 1);
        return repeatedQuestion;
      } else {
        setCount(count + 1);
        const selectedQuestion = remainingQuestions[randomIndex];
        const updatedQuestions = [...remainingQuestions];
        updatedQuestions.splice(randomIndex, 1);
        setRemainingQuestions(updatedQuestions);
        return selectedQuestion;
      }
    }
  };
  
  // Menjalankan getRandomQuestion
  const handleNextQuestion = () => {
    const question = getRandomQuestion();
    console.log(question.pertanyaan);
    if (question.pertanyaan === 'The Game is Finished!') {
      setEnd(true);
    }
    setCurrentQuestion(question);
  };

  return (
    <div 
      className="w-full h-full flex flex-col items-center justify-center gap-5 text-center py-[1 0vh]"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/QuestionPage/bg.png)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {currentQuestion && (
        <>
          <h2 className='w-[90vw] h-[5vh] text-[20px] md:text-[32px] font-yeseva'>
            {currentQuestion.tujuan === "Commoners" ? (
              <span className='text-[#DC615C]'>{currentQuestion.tujuan}</span>
            ) : (
              <span className=''>{currentQuestion.tujuan}</span>
            )}
          </h2>
          <div className='flex flex-col justify-between items-center w-full h-[200px]'>
            <div className='h-[80px] text-[15px] md:text-[16px] lg:text-[22px] xl:text-[24px] text-[#901B2B] w-full bg-[#D1B288] border-4 border-[#901B2B] flex justify-center items-center font-yeseva p-1'>
              <span>{currentQuestion.pertanyaan}</span>
            </div>
            <p className='w-[85vw] h-[100px] text-[12px] md:text-[14px] lg:text-[18px] text-[#7B4528]'>
              {currentQuestion.penilai}
              {currentQuestion.penilai !== '' && (
                <span>: </span>
              )}
              {currentQuestion.penilaian}
            </p>
          </div>
          {!end && (
            <button className='w-[147px] h-[57px] bg-[#7B4528] rounded-full font-yeseva' onClick={handleNextQuestion}>
              <span className='text-[20px] text-[#D1B288]'>Next</span>
            </button>
            )
          } : {
            <div></div>
          } 
        </>
      )}
    </div>
  );
};

export default QuestionPage;

// Sample Data
const questions: Question[] = [
  {
    "tujuan": "Commoners",
    "pertanyaan": "What's the king's pet peeves?",
    "penilaian": "Give one of the card in your hand to the commoner with answer closest to the truth. You can choose not to if there's no answer that satisfies you.",
    "penilai": "King"
  },
  {
    "tujuan": "Commoners",
    "pertanyaan": "Guess what's the king's biggest fear!",
    "penilaian": "Give one of the card in your hand to the commoner with answer closest to the truth. You can choose not to if there's no answer that satisfies you.",
    "penilai": "King"
  },
  {
    "tujuan": "Commoners",
    "pertanyaan": "Tell the king your first impression of them and how you see them right now",
    "penilaian": "Give one of the card in your hand to the commoner with the most shocking answer.",
    "penilai": "King"
  },
  {
    "tujuan": "Commoners",
    "pertanyaan": "Guess the king's guilty pleasure",
    "penilaian": "Give one of the card in your hand to the commoner with answer closest to the truth. You can choose not to if there's no answer that satisfies you.",
    "penilai": "King"
  },
  {
    "tujuan": "Commoners",
    "pertanyaan": "What hobby do you think would surprisingly suit the king?",
    "penilaian": "Give one of the card in your hand to the commoner with an answer that you would like to try. You can choose not to if there's no answer that piques your interest.",
    "penilai": "King"
  },
  {
    "tujuan": "Commoners",
    "pertanyaan": "If you could make the king's life into a book, what would the title be?",
    "penilaian": "Give one of the card in your hand to the commoner with the answer that satisfies you the most. You can choose not to if there's no answer that satisfies you.",
    "penilai": "King"
  },
  {
    "tujuan": "Commoners",
    "pertanyaan": "Tell the king a trivia or a fun fact",
    "penilaian": "Give one of the card in your hand to the commoner with the most shocking answer. Don't give your card if you know every answer already.",
    "penilai": "King"
  },
  {
    "tujuan": "Commoners",
    "pertanyaan": "If you could invent a new holiday for the king, what would it be and how would people celebrate it?",
    "penilaian": "Give one of the card in your hand to the commoner with the answer that satisfies you the most. You can choose not to if there's no answer that satisfies you.",
    "penilai": "King"
  },
  {
    "tujuan": "Commoners",
    "pertanyaan": "Tell the king a trivia or a fun fact",
    "penilaian": "Give one of the card in your hand to the commoner with the most shocking answer. Don't give your card if you know every answer already.",
    "penilai": "King"
  },
  {
    "tujuan": "Commoners",
    "pertanyaan": "Tell a joke to the King!",
    "penilaian": "Give one of the card in your hand to the commoner with the funniest answer. You can choose not to if there's no answer that satisfies you.",
    "penilai": "King"
  },
  {
    "tujuan": "Commoners",
    "pertanyaan": "What is the King's biggest dream?",
    "penilaian": "Give one of the card in your hand to the commoner with the closest answer. You can choose not to if there's no answer that satisfies you.",
    "penilai": "King"
  },
  {
    "tujuan": "Commoners",
    "pertanyaan": "If the King could swap lives with an animal for a day, which one would it be?",
    "penilaian": "Give one of the card in your hand to the commoner with answer closest to the truth. You can choose not to if there's no answer that satisfies you.",
    "penilai": "King"
  },
  {
    "tujuan": "Commoners",
    "pertanyaan": "If the King is an actor/actress, what is their character?",
    "penilaian": "Give one of the card in your hand to the commoner with answer closest to the truth. You can choose not to if there's no answer that satisfies you.",
    "penilai": "King"
  },
  {
    "tujuan": "Commoners",
    "pertanyaan": "Guess King's favorite singer!",
    "penilaian": "Give one of the card in your hand to the commoner with answer closest to the truth. You can choose not to if there's no answer that satisfies you.",
    "penilai": "King"
  },
  {
    "tujuan": "Commoners",
    "pertanyaan": "Guess the King's favorite super power?",
    "penilaian": "Give one of the card in your hand to the commoner with answer closest to the truth. You can choose not to if there's no answer that satisfies you.",
    "penilai": "King"
  },
  {
    "tujuan": "King",
    "pertanyaan": "Who among the commoners is most likely to get married first?",
    "penilaian": "If the majority disagree with the king's opinion, the king should give one of you their card. Discuss among yourselves who should receive the card.",
    "penilai": "Commoner"
  },
  {
    "tujuan": "King",
    "pertanyaan": "Who among the commoners is most likely to cheat on their partner?",
    "penilaian": "If the majority disagree with the king's opinion, the king should give one of you their card. Discuss among yourselves who should receive the card.",
    "penilai": "Commoner"
  },
  {
    "tujuan": "King",
    "pertanyaan": "Who among the commoners is most likely to be a president?",
    "penilaian": "If the majority disagree with the king's opinion, the king should give one of you their card. Discuss among yourselves who should receive the card.",
    "penilai": "Commoner"
  },
  {
    "tujuan": "King",
    "pertanyaan": "If you're stranded on an island and could only take one of the commoners with you, who would you take and why?",
    "penilaian": "If the majority disagree with the king's opinion, the king should give one of you their card. Discuss among yourselves who should receive the card.",
    "penilai": "Commoner"
  },
  {
    "tujuan": "King",
    "pertanyaan": "Who between the commoners is most likely to go to jail and for what?",
    "penilaian": "If the majority disagree with the king's opinion, the king should give one of you their card. Discuss among yourselves who should receive the card.",
    "penilai": "Commoner"
  },
  {
    "tujuan": "King",
    "pertanyaan": "Who between the commoners is most likely to be secretly an alien in disguise?",
    "penilaian": "If the majority disagree with the king's opinion, the king should give one of you their card. Discuss among yourselves who should receive the card.",
    "penilai": "Commoner"
  },
  {
    "tujuan": "King",
    "pertanyaan": "Who among the commoners is most likely to be an international fugitive?",
    "penilaian": "If the majority disagree with the king's opinion, the king should give one of you their card. Discuss among yourselves who should receive the card.",
    "penilai": "Commoner"
  },
  {
    "tujuan": "King",
    "pertanyaan": "Who among the commoners looks like he/she will be a philosophy professor?",
    "penilaian": "If the majority disagree with the king's opinion, the king should give one of you their card. Discuss among yourselves who should receive the card.",
    "penilai": "Commoner"
  },
  {
    "tujuan": "King",
    "pertanyaan": "Who between the commoners is most likely to survive for a whole year in a cow ranch?",
    "penilaian": "If the majority disagree with the king's opinion, the king should give one of you their card. Discuss among yourselves who should receive the card.",
    "penilai": "Commoner"
  },
  {
    "tujuan": "King",
    "pertanyaan": "Who between the commoners is most likely to do nothing for 12 hours straight?",
    "penilaian": "If the majority disagree with the king's opinion, the king should give one of you their card. Discuss among yourselves who should receive the card.",
    "penilai": "Commoner"
  },
  {
    "tujuan": "King",
    "pertanyaan": "Who between the commoners is most likely to marry into the Royal Family?",
    "penilaian": "If the majority disagree with the king's opinion, the king should give one of you their card. Discuss among yourselves who should receive the card.",
    "penilai": "Commoner"
  },
  {
    "tujuan": "King",
    "pertanyaan": "Who between the commoners is most likely to die first in a horror movie?",
    "penilaian": "If the majority disagree with the king's opinion, the king should give one of you their card. Discuss among yourselves who should receive the card.",
    "penilai": "Commoner"
  },
  {
    "tujuan": "King",
    "pertanyaan": "If you're inside a zombie apocalypse, who among the commoners will you trust with your life and why?",
    "penilaian": "If the majority disagree with the king's opinion, the king should give one of you their card. Discuss among yourselves who should receive the card.",
    "penilai": "Commoner"
  },
  {
    "tujuan": "King",
    "pertanyaan": "If you have to choose someone from the commoners to deal with your country's money, who will you choose?",
    "penilaian": "If the majority disagree with the king's opinion, the king should give one of you their card. Discuss among yourselves who should receive the card.",
    "penilai": "Commoner"
  },
  {
    "tujuan": "King",
    "pertanyaan": "Who among the commoners is most likely to build an airplane from scratch?",
    "penilaian": "If the majority disagree with the king's opinion, the king should give one of you their card. Discuss among yourselves who should receive the card.",
    "penilai": "Commoner"
  }    
];

const specialQuestion: Question = {
  tujuan: 'Commoners',
  pertanyaan: 'Take one card from the pile and discard one card from your hand!',
  penilaian: 'or you can take a card from the discard pile of the player on your right.',
  penilai: ''
};

const repeatedQuestion: Question = {
  tujuan: 'Commoners',
  pertanyaan: 'Take one card from the hand of the commoner on your right! No peeking!',
  penilaian: 'and let the commoner to your left take one of your card',
  penilai: ''
}

const finished: Question = {
  tujuan: '',
  pertanyaan: 'The Game is Finished!',
  penilaian: 'Thank you for playing!',
  penilai: ''
}