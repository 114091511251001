import React, { useState, ChangeEvent, FormEvent } from 'react';
import { firestore } from '../services/firebase';

interface FormData {
  tujuan: string;
  pertanyaan: string;
  penilaian: string;
  penilai: string;
}

const Form: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    tujuan: '',
    pertanyaan: '',
    penilaian: '',
    penilai: '',
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      // Add a new document with a generated id to the "users" collection
      await firestore.collection('questions').add({
        tujuan: formData.tujuan,
        pertanyaan: formData.pertanyaan,
        penilaian: formData.penilaian,
        penilai: formData.penilai,
      });

      // Clear the form after submitting
      setFormData({
        tujuan: '',
        pertanyaan: '',
        penilaian: '',
        penilai: '',
      });

      console.log('Data added to Firestore');
    } catch (error) {
      console.error('Error adding data to Firestore: ', error);
    }
  };

  return (
    <div>
      <h1>Add Data to Firestore</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Tujuan:
          <input type="text" name="tujuan" value={formData.tujuan} onChange={handleInputChange} />
        </label>
        <br />
        <label>
          Pertanyaan:
          <input
            type="text"
            name="pertanyaan"
            value={formData.pertanyaan}
            onChange={handleInputChange}
          />
        </label>
        <br />
        <label>
          Penilaian:
          <input
            type="text"
            name="penilaian"
            value={formData.penilaian}
            onChange={handleInputChange}
          />
        </label>
        <br />
        <label>
          Penilai:
          <input type="text" name="penilai" value={formData.penilai} onChange={handleInputChange} />
        </label>
        <br />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default Form;
