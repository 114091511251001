import React from 'react';
import { Link } from 'react-router-dom';

const LandingPage: React.FC = () => {
  return (
    <div className="w-full h-full relative flex flex-col items-center">
      <div className='h-[300px] w-full text-center z-10 lg:h-[500px]' 
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/LandingPage/header.png)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
        <div className="w-full h-full bg-gradient-to-b from-[#D9D9D9]/0 to-[#242323]/80">
          <h2 className='text-[#FFFFF0] text-[28px] md:text-[36px] lg:text-[48px] w-full pt-16 md:pt-20 lg:pt-24'>
            MAKING YOUR AMUSING ATMOSPHERE
          </h2>
        </div>
      </div>
      <div className='w-[210px] lg:w-[500px] h-[60px] lg:h-[80px] bg-white z-30 absolute bottom-[66vh] lg:bottom-[55vh] rounded-full flex items-center justify-center' style={{ backgroundColor: 'rgba(255, 255, 240, 0.6)' }}>
        <img src='/images/AmusphereLogo.png' alt='logo' className='h-[38px] lg:h-[55px]' />
      </div>
      <div className='h-[70vh] lg:h-[60vh] w-full bg-[#DC615C] z-20 absolute bottom-0 rounded-t-[10%] lg:rounded-t-[5%] flex flex-col lg:flex-row items-center justify-center lg:justify-evenly gap-4 pt-6 text-center text-[18px] lg:text-[36px]'>
        <Link to="/product" className="text-[#10181f] z-40">
          <div className='w-[260px] lg:w-[400px] h-[160px] lg:h-[240px] bg-white rounded-[26px] flex flex-col justify-between overflow-hidden'>
            <img src='/images/LandingPage/product.png' alt='logo' className='w-full h-[125px] lg:h-[180px]' />       
            <p className='w-full h-full text-[#901B2B] pt-1'>PRODUCTS</p>
          </div>
        </Link>
        <Link to="/contact" className="text-[#10181f] z-40">
          <div className='w-[260px] lg:w-[400px] h-[160px] lg:h-[240px] bg-white rounded-[26px] flex flex-col justify-between overflow-hidden'>
            <img src='/images/LandingPage/contact.png' alt='logo' className='w-full h-[125px] lg:h-[180px]' />       
            <p className='w-full h-full text-[#901B2B] pt-1'>CONTACT</p>
          </div>
        </Link>
        <div className='w-full h-[120px] z-30 absolute bottom-0 bg-gradient-to-b from-[#FFFFFF]/0 to-[#FFFFF0]/100'></div>
      </div>
    </div>
  );
};

export default LandingPage;
